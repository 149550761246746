import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { useNavigate } from 'react-router-dom';

import BarChart from './barCharts/BarChart';
import LineChart from './lineCharts/LineChart';

import {
  getAgentRequests,
  getAllRequestsByAgent,
  getChildrenRequest,
} from 'reduxTK/slices/requests/requestsAsyncThunk';
import { getAgentPropositions } from 'reduxTK/slices/propositions/propositionsAsyncThunk';
import {getTopAgents } from 'reduxTK/slices/filter/filterAsyncThunk';
import styles from './agentHome.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'reduxTK/store/rootReducer';
import { IoStarOutline } from 'react-icons/io5';

type Period = "month" | "year";

const AgentHome = () => {
  const dispatch = useAppDispatch();
  const requestsByAgent: any = useAppSelector((state) => state.requests.requestsByAgent.data);

  const [agentRequestIds, setAgentRequestIds] = useState('');

  const navigate = useNavigate();
    const isAgent = localStorage.getItem('role') === 'AGENT';
    
    const {
      monthAgents,
      yearAgents,
    } = useSelector((state: RootState) => ({
      monthAgents: state.filter.monthAgents.data,
      yearAgents: state.filter.yearAgents.data,
    }));
  useEffect(() => {
    let arg = null
    dispatch(getAgentRequests());
    isAgent
      ? dispatch(getAllRequestsByAgent(arg))
      : dispatch(getChildrenRequest()).then((res) => dispatch(getAllRequestsByAgent(res?.payload)));
    
  }, [dispatch ,isAgent]);

  useEffect(() => {
    if (requestsByAgent && requestsByAgent?.data) {
      requestsByAgent?.data?.map((req: any) => {
        if (agentRequestIds === '') {
          setAgentRequestIds((prevState: any) => `${req.id}`);
          return null;
        } else {
          setAgentRequestIds((prevState: any) => `${prevState},${req.id}`);
          return null;
        }
      });
    }
  }, [requestsByAgent]); 

  useEffect(() => {
    if (agentRequestIds !== '') {
      dispatch(getAgentPropositions(agentRequestIds));
    }
  }, [agentRequestIds, dispatch]);

  useEffect(() => {
    dispatch(getTopAgents("month")); 
    dispatch(getTopAgents("year")); 
  }, [dispatch]);
  return (
    <div className={styles.agentHome}>
      <section className={styles.projects}>
        <div className={styles.projects_charts}>
          <LineChart title="Nouveaux Projets" />
          <BarChart title="Projets ouverts" />
          <BarChart title="Projets fermés" />
        </div>
        <button className={styles.projects_btn} onClick={() => navigate('/projectsList')}>ACCEDEZ AU PROJETS</button>
      </section>
      <section>
        <div className={styles.proposals}>
          <div className={styles.proposals_charts}>
            <LineChart title="nouvelles propositions" />
            <LineChart title="propositions accéptées" />
          </div>
          <button className={styles.proposals_btn} onClick={() => navigate('/propositionsList')}>ACCEDEZ AU PROPOSITIONS</button>
        </div>
      </section>
      {!isAgent && (
  <div className={styles.topAgentsContainer}>
    {/* Section pour les Top Agents Annuel */}
    <section className={styles.topAgents}>
      <div className={styles.topAgents_group}>
        <h3 className={styles.topAgents_group_title}>TOP 5 AGENTS-ANNUEL</h3>
        <h5 className={styles.topAgents_group_subtitle}>Par dossiers financiés cette année</h5>
        {(yearAgents||[]).length > 0 ? (
          (yearAgents||[]).map((agent: any, index: number) => (
            <div key={index} className={styles.topAgents_agent}>
              <div className={styles.topAgents_agent_details}>
                <span className={styles.topAgents_agent_icon}>
                  <IoStarOutline />
                </span>
                <span className={styles.topAgents_agent_name}>{agent.agent}</span>
              </div>
              <span className={styles.topAgents_agent_note}>{agent.requests}</span>
            </div>
          ))
        ) : (
          <p>Aucun agent trouvé pour cette année.</p>
        )}
      </div>
    </section>

    {/* Section pour les Top Agents Mensuel */}
    <section className={styles.topAgents}>
      <div className={styles.topAgents_group}>
        <h3 className={styles.topAgents_group_title}>TOP 5 AGENTS-MENSUEL</h3>
        <h5 className={styles.topAgents_group_subtitle}>Par dossiers financiés ce mois</h5>
        {(monthAgents||[]).length > 0 ? (
          (monthAgents||[]).map((agent: any, index: number) => (
            <div key={index} className={styles.topAgents_agent}>
              <div className={styles.topAgents_agent_details}>
                <span className={styles.topAgents_agent_icon}>
                  <IoStarOutline />
                </span>
                <span className={styles.topAgents_agent_name}>{agent.agent}</span>
              </div>
              <span className={styles.topAgents_agent_note}>{agent.requests}</span>
            </div>
          ))
        ) : (
          <p>Aucun agent trouvé pour ce mois.</p>
        )}
      </div>
    </section>
  </div>
)}


      
    </div>
  );
};

export default AgentHome;
