import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from 'common/hooks';
import { Link, useNavigate } from 'react-router-dom';

import SideMenu from 'components/layout/sidemenu/SideMenu';
import LOGO from 'assets/logos/intergestion-logo.png';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdClose } from 'react-icons/md';
import { IoPersonCircleOutline } from 'react-icons/io5';
// import { HiInformationCircle } from 'react-icons/hi';

import { CiUser } from "react-icons/ci";

import { logout } from 'reduxTK/slices/authentication/authSlice';

import styles from './Header.module.scss';

interface HeaderProps {
  isAuthenticated: boolean;
  role: string;
}

const Header: React.FC<HeaderProps> = ({ isAuthenticated, role }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const pathname = window.location.pathname;

  const [isMenuOpen, setMenuOpen] = useState(false);

  const menuRef = useRef<HTMLDivElement | null>(null);

  // Function to close the menu
  const closeMenu = () => {
    setMenuOpen(false);
  };

  const logOutHandler = () => {
    dispatch(logout());
    navigate('/');
  };

  // Add a click event listener to the document
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        closeMenu();
      }
    };

    // Attach the event listener when the menu is open
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      // Remove the event listener when the menu is closed
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <div className={styles.headerContainer}>
      <header className={styles.header}>
        <button className={styles.sidemenu} onClick={() => setMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? <MdClose size={25} /> : <GiHamburgerMenu size={25} />}
        </button>
        <div className={styles.logo}>
          <Link to="/">
            <img className={styles.imgLogo} src={LOGO} alt="logo" />
          </Link>
        </div>
        <nav className={`navigation  ${isMenuOpen ? 'active' : ''}`}>
          {/* Navigation items for desktop view */}
          {role === 'VISITOR' ? (
            <>
              <Link to="/" className={pathname === '/' ? styles.nav__item__active : styles.nav__item}>
                ACCUEIL
              </Link>
              <Link
                to="/contact"
                className={pathname === '/contact' ? styles.nav__item__active : styles.nav__item}
              >
                CONTACTEZ-NOUS
              </Link>
            </>
          ) : role === 'APPLICANT' ? (
              <>
                <Link
                  to="/applicant/home"
                  className={
                    pathname === '/applicant/home' ? styles.nav__item__active : styles.nav__item
                  }
                >
                  ACCUEIL
                </Link>
                <Link
                  to="/projectsList"
                  className={pathname === '/projectsList' ? styles.nav__item__active : styles.nav__item}
                >
                  VOS PROJETS
                </Link>
                <Link
                  to="/propositionsList"
                  className={
                    pathname === '/propositionsList' ? styles.nav__item__active : styles.nav__item
                  }
                >
                  VOS PROPOSITION
                </Link>
                <Link
                  to="/addressBookPage"
                  className={
                    pathname === '/addressBookPage' ? styles.nav__item__active : styles.nav__item
                  }
                >
                  CARNET D'ADRESSES
                </Link>
                <a
                href="/Intergestion-financement.pdf"
                target='_blank'
                rel="noopener noreferrer"
                className={pathname === '/projects' ? styles.nav__item__active : styles.nav__item}
              >
                CONDITIONS FINANCEMENT
              </a>
              <Link
                to="/about"
                className={pathname === '/about' ? styles.nav__item__active : styles.nav__item}
              >
                TUTO INSCRIPTION
              </Link>
                <Link
                  to="/contact"
                  className={pathname === '/contact' ? styles.nav__item__active : styles.nav__item}
                >
                  CONTACTEZ-NOUS
                </Link>
              </>
            ) :
            role === 'AGENT' ?
              (
                <>
                  <Link
                    to="/agent/home"
                    className={pathname === '/agent/home' ? styles.nav__item__active : styles.nav__item}
                  >
                    ACCUEIL
                  </Link>
                  <Link
                    to="/projectsList"
                    className={pathname === '/projectsList' ? styles.nav__item__active : styles.nav__item}
                  >
                    LES PROJETS
                  </Link>
                  <Link to="/propositionsList"
                        className={pathname === '/propositionsList' ? styles.nav__item__active : styles.nav__item}>
                    LES PROPOSITIONS
                  </Link>
                  <Link
                    to="/contact"
                    className={pathname === '/contact' ? styles.nav__item__active : styles.nav__item}
                  >
                    CONTACTEZ-NOUS
                  </Link>
                </>
              )
              : (

                <>
                  <Link
                    to="/agent/home"
                    className={pathname === '/agent/home' ? styles.nav__item__active : styles.nav__item}
                  >
                    ACCUEIL
                  </Link>
                  <Link
                    to="/projectsList"
                    className={pathname === '/projectsList' ? styles.nav__item__active : styles.nav__item}
                  >
                    LES PROJETS
                  </Link>
                  <Link to="/propositionsList"
                        className={pathname === '/propositionsList' ? styles.nav__item__active : styles.nav__item}>
                    LES PROPOSITIONS
                  </Link>
                  <Link to="/usersList"
                        className={pathname === '/usersList' ? styles.nav__item__active : styles.nav__item}>
                    LES UTILISATEURS
                  </Link>
                  <Link to="/affiliations"
                        className={pathname === '/affiliations' ? styles.nav__item__active : styles.nav__item}>
                    AFFILIATIONS
                  </Link>
                  <Link
                    to="/contact"
                    className={pathname === '/contact' ? styles.nav__item__active : styles.nav__item}
                  >
                    CONTACTEZ-NOUS
                  </Link>
                </>
              )}
          {isAuthenticated && (
            <span onClick={logOutHandler} className={styles.nav__item}>
            DECONNEXION
          </span>
          )}
        </nav>
        <div className={styles.icons}>
          {/* <a href="https://www.tbdgroup.com/" target="_blank" rel="noreferrer">
            <HiInformationCircle size={30} />
          </a> */}
          {!isAuthenticated && (
            <Link to="/login">
              <IoPersonCircleOutline size={30} />
            </Link>
          )}
          {isAuthenticated && (
            <Link to="/ProfileDetail">
              <CiUser size={30} />
            </Link>
          )}
        </div>
      </header>

      <div ref={menuRef}>
        <SideMenu role={role} isOpen={isMenuOpen} onClose={() => setMenuOpen(false)} />
      </div>
    </div>
  );
};

export default Header;
