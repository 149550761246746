const API_HOST = 'https://api.green-city.prod-projet.com/';
const API_HOST_FICODEV = 'https://hub.prexange.com';
//const API_HOST_FICODEV = 'https://pp.hub.prexange.com';
const API = {
  auth: {
    login: '/api/login',
    user: '/admin/api/customer_users',
    editAddress: (addressId: string | undefined) =>
      `/admin/api/customer_user_addresses/${addressId}`,
    addAddress: '/admin/api/customer_user_addresses',
    oneAddress: (userId: string | null, addressId: any) =>
      `/admin/api/customer_users/${userId}/addresses?id=${addressId}`,
    allAddress: (userId: string) => `/admin/api/customer_users/${userId}/addresses`,
    customerUserRoles: (id: string) => `/admin/api/customer_user_roles/${id}`,
    updatePassword: '/admin/api/customerUser/password/update',
  },
  customer: {
    allCustomer : '/admin/api/customers?limit=1000',
    customerById: (id: string) => `/admin/api/customers/${id}`,
    applicants : '/admin/api/customer_users?roles=2&limit=1000',
    agents : '/admin/api/customer_users?roles=4&limit=1000',
    top_agents : (period: string) =>`/admin/api/customerUser/top_agents?period=${period}`
  },
  users:'/admin/api/users',
  requests: {
    requests: '/admin/api/requests',
    all: '/admin/api/requests',
    requestById: (id: string) => `/admin/api/requests/${id}`,
    requestCustomerById: (id: string) => `/admin/api/requests/${id}/customer`,
    requestInternalStatus: (id: string) => `/admin/api/requests/${id}/internal_status`,
    requestAssignedUser: (id: string) => `/admin/api/requests/${id}/assignedUsers`,
    requsetUsageBien: (id: string) => `/admin/api/requests/${id}/s_usage_bien`,
    requsetTypeBien: (id: string) => `/admin/api/requests/${id}/s_type_bien`,
    requestInternalStatusById: (id: string) => `/admin/api/rfpinternalstatuses/${id}`,
    requsetUsageBienById: (id: string) => `/admin/api/requestsusagebien25628e96s/${id}`,
    requsetTypeBienById: (id: string) => `/admin/api/requeststypebiend60e813es/${id}`,
    requestModaliteAchatById: (id: string) => `/admin/api/requestsmodaliteachatfa695442s/${id}`,
    requestModeAchatById: (id: string) => `/admin/api/requestsmodeachat1f10f2a2s/${id}`,
    requestEtatAvancementById: (id: string) => `/admin/api/requestsetatavancement3650e254s/${id}`,
    requestSituationProById: (id: string) => `/admin/api/requestssituationpro3c4f720bs/${id}`,
    agentRequests: (s_email_agent: string | null) =>
      `/admin/api/requests?s_email_agent=${s_email_agent}`,
      childrenRequest: (storedCustomerId: string) => `/admin/api/customers/${storedCustomerId}/children`,
  },
  propositions: {
    allPropositions: '/admin/api/ficodevquotes',
    filteredAdminPropositions: (agency: string, agent: string, status: string ,endDate?:string ,startDate?:string ,sort?:string ,order?:string) =>
      `/admin/api/ficodevquotes?customer=${agency}&request=${agent}&createdAt[gte]=${startDate}&createdAt[lte]=${endDate}&internal_status=${status}&sort=${order}${sort}&limit=1000&page=1`,
    propositionbyCustomerUser: (id: string, agency: string, agent: string, status: string ,endDate:string ,startDate:string ,sort?:string ,order?:string) =>
      `/admin/api/ficodevquotes?customerUser=${id}&customer=${agency}&internal_status=${status}&createdAt[gte]=${startDate}&createdAt[lte]=${endDate}&sort=${order}${sort}&limit=1000&page=1`,
    propositionTypeBien: (id: string) => `/admin/api/ficodevquotes?customer=${id}`,
    propositionInternalStatus: `/admin/api/quoteinternalstatuses`,
    propositionInternalStatusById: (id: string) => `/admin/api/quoteinternalstatuses/${id}`,
  },
  static: {
    TERMS: 'items/page_intergestion/1',
    PROJECT: 'items/page_intergestion/3',
    ABOUT: 'items/page_intergestion/4',
    LEGAL: 'items/page_intergestion/5',
    confirmRegisterContent: 'items/page_intergestion/7',
    editProjectContent: 'items/page_intergestion/8',
    projectAddConfirmation: 'items/page_intergestion/9',
    projectList: 'items/page_intergestion/11',
    requestList : 'items/page_intergestion/12',
    profile : 'items/page_intergestion/13',
    usersList: 'items/page_intergestion/14',
    adressesBook: 'items/page_intergestion/15',
    adresseList: 'items/page_intergestion/16',
    contact: 'items/contact',
  },
  roadMap: {
    steps: 'items/roadmap_intergestion',
  },
  carousel: {
    slides: 'items/slider_intergestion_files',
  },
  assets: 'assets',
  internalstatuses: {
    all: '/admin/api/rfpinternalstatuses?limit=1000',
    internalstatuseById: (id: string) => `/admin/api/rfpinternalstatuses/${id}`,
  },
  requestDropDowns: {
    situationPro: '/admin/api/requestssituationpro3c4f720bs?page=1&limit=50',
    typeBien: '/admin/api/requeststypebiend60e813es?page=1&limit=50',
    usageBien: '/admin/api/requestsusagebien25628e96s?page=1&limit=50',
    modaliteAchat: '/admin/api/requestsmodaliteachatfa695442s?page=1&limit=50',
    modeAchat: '/admin/api/requestsmodeachat1f10f2a2s?page=1&limit=50',
    etatAvancement: '/admin/api/requestsetatavancement3650e254s?page=1&limit=50',
    dureeCreditSouhaite: '/admin/api/requestsdureecreditsouhaite7928f01s?page=1&limit=50',
  },
  files: (id: string) => `/admin/api/files/${id}`,
  addressBook: {
    countries : '/admin/api/countries',
    regions : '/admin/api/regions',
  },
  password: {
    reset: '/admin/api/customerUser/password_reset/request',
    newPassword: '/admin/api/customerUser/password_reset/reset',
  },
  affiliations: {
    allAffiliations: '/admin/api/extendaffiliations',
   // filteredAdminPropositions: (agency: string, agent: string, status: string ,endDate?:string ,startDate?:string ,sort?:string ,order?:string) =>
     // `/admin/api/ficodevquotes?customer=${agency}&request=${agent}&createdAt[gte]=${startDate}&createdAt[lte]=${endDate}&internal_status=${status}&sort=${order}${sort}&limit=1000&page=1`,
   // propositionbyCustomerUser: (id: string, agency: string, agent: string, status: string ,endDate:string ,startDate:string ,sort?:string ,order?:string) =>
    //  `/admin/api/ficodevquotes?customerUser=${id}&customer=${agency}&internal_status=${status}&createdAt[gte]=${startDate}&createdAt[lte]=${endDate}&sort=${order}${sort}&limit=1000&page=1`,
  //  propositionTypeBien: (id: string) => `/admin/api/ficodevquotes?customer=${id}`,
   // propositionInternalStatus: `/admin/api/quoteinternalstatuses`,
   // propositionInternalStatusById: (id: string) => `/admin/api/quoteinternalstatuses/${id}`,
  },
  
};
export { API, API_HOST, API_HOST_FICODEV };
