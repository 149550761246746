import { useAppDispatch } from 'common/hooks';
import { Link, useNavigate } from 'react-router-dom';

import { logout } from 'reduxTK/slices/authentication/authSlice';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import styles from './Sidemenu.module.scss';

interface MenuProps {
  isOpen: boolean;
  role: string;
  onClose: () => void;
}

const SideMenu: React.FC<MenuProps> = ({ isOpen, onClose, role }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logOutHandler = () => {
    dispatch(logout());
    onClose();
    navigate('/');
  };

  if (!isOpen) {
    return null;
  }

  return role === 'VISITOR' ? (
    <div className={styles.sidemenu}>
      <button className={styles.sidemenu_icon__close} onClick={onClose}>
        <AiOutlineCloseCircle color="white" size={27} />
      </button>
      <Link className={styles.sidemenu__item} to="/" onClick={onClose}>
        ACCUEIL
      </Link>
    
      <Link className={styles.sidemenu__item} to="/contact" onClick={onClose}>
        CONTACTEZ-NOUS
      </Link>
    </div>
  ) : role === 'APPLICANT' ? (
    <div className={styles.sidemenu}>
      <button className={styles.sidemenu_icon__close} onClick={onClose}>
        <AiOutlineCloseCircle color="white" size={27} />
      </button>
      <Link className={styles.sidemenu__item} to="/applicant/home" onClick={onClose}>
        ACCUEIL
      </Link>
      <Link className={styles.sidemenu__item} to="/editProjectApplicant" onClick={onClose}>
        UN PROJET
      </Link>
      <Link className={styles.sidemenu__item} to="/projectsList" onClick={onClose}>
        VOS PROJETS
      </Link>
      <Link className={styles.sidemenu__item} to="/propositionsList" onClick={onClose}>
        VOS PROPOSITIONS
      </Link>
      <Link to="/addressBookPage" className={styles.sidemenu__item} onClick={onClose}>
        CARNET D'ADRESSES
      </Link>
      <a
        href="/Intergestion-financement.pdf"
        target='_blank'
        rel="noopener noreferrer"
        
      >
        CONDITIONS FINANCEMENT
      </a>
      <Link className={styles.sidemenu__item} to="/about" onClick={onClose}>
        TUTO INSCRIPTION
      </Link>
      <Link className={styles.sidemenu__item} to="/contact" onClick={onClose}>
        CONTACTEZ-NOUS
      </Link>
      <span onClick={logOutHandler} className={styles.sidemenu__item}>
        DECONNEXION
      </span>
    </div>
  ) : role === 'AGENT' ? (
    <div className={styles.sidemenu}>
      <button className={styles.sidemenu_icon__close} onClick={onClose}>
        <AiOutlineCloseCircle color="white" size={27} />
      </button>
      <Link className={styles.sidemenu__item} to="/agent/home" onClick={onClose}>
        ACCUEIL
      </Link>
      <Link className={styles.sidemenu__item} to="/projectsListPage" onClick={onClose}>
        LES PROJETS
      </Link>
      <Link className={styles.sidemenu__item} to="/propositionsList" onClick={onClose}>
        LES PROPOSITIONS
      </Link>
      <Link to="/addressBookPage" className={styles.sidemenu__item} onClick={onClose}>
        LES ADRESSES
      </Link>
      <Link className={styles.sidemenu__item} to="/contact" onClick={onClose}>
        CONTACTEZ-NOUS
      </Link>
      <span onClick={logOutHandler} className={styles.sidemenu__item}>
        DECONNEXION
      </span>
    </div>
  ) : (
    <div className={styles.sidemenu}>
      <button className={styles.sidemenu_icon__close} onClick={onClose}>
        <AiOutlineCloseCircle color="white" size={27} />
      </button>
      <Link className={styles.sidemenu__item} to="/agent/home" onClick={onClose}>
        ACCUEIL
      </Link>
      <Link className={styles.sidemenu__item} to="/projectsListPage" onClick={onClose}>
        LES PROJETS
      </Link>
      <Link className={styles.sidemenu__item} to="/propositionsList" onClick={onClose}>
        LES PROPOSITIONS
      </Link>
      <Link to="/addressBookPage" className={styles.sidemenu__item} onClick={onClose}>
        LES ADRESSES
      </Link>
      <Link to="/usersList" className={styles.sidemenu__item} onClick={onClose}>
        LES UTILISATEURS
      </Link>
      <Link className={styles.sidemenu__item} to="/contact" onClick={onClose}>
        CONTACTEZ-NOUS
      </Link>
      <span onClick={logOutHandler} className={styles.sidemenu__item}>
        DECONNEXION
      </span>
    </div>
  );
};

export default SideMenu;
