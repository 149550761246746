import { createSlice } from '@reduxjs/toolkit';
import { getAllCustomers , getrfpinternalstatuses ,getAllApplicants , getAllAgents , getAllPropositionsStatuses,getTopAgents} from './filterAsyncThunk';

const reduxState = {
    data: null,
    status: 'loading',
    error: null
};

const initialState = {
    allCustomers : reduxState,
    allInternalStatuses : reduxState,
    allApplicants : reduxState,
    allAgents : reduxState,
    allPropositionsStatuses : reduxState,
    monthAgents : reduxState,
    yearAgents : reduxState
};

export const filterSlice = createSlice(
    {
        name: 'filter',
        initialState,
        reducers: {},
        extraReducers: (builder) => {
            builder
            // getAllCustomers
                .addCase(getAllCustomers.pending, state => {
                    return { ...state, allCustomers: { ...state.allCustomers, status: 'loading' } };
                })
                .addCase(getAllCustomers.fulfilled, (state, action) => {
                    return { ...state, allCustomers: { ...state.allCustomers, status: 'success', data: action.payload } };
                })
                .addCase(getAllCustomers.rejected, (state, action) => {
                    return { ...state, allCustomers: { ...state.allCustomers, status: 'failed', error: (action.payload as any).error } };
                })
            // getrfpinternalstatuses
                .addCase(getrfpinternalstatuses.pending, state => {
                    return { ...state, allInternalStatuses: { ...state.allInternalStatuses, status: 'loading' } };
                })
                .addCase(getrfpinternalstatuses.fulfilled, (state, action) => {
                    return { ...state, allInternalStatuses: { ...state.allInternalStatuses, status: 'success', data: action.payload } };
                })
                .addCase(getrfpinternalstatuses.rejected, (state, action) => {
                    return { ...state, allInternalStatuses: { ...state.allInternalStatuses, status: 'failed', error: (action.payload as any).error } };
                })
            // getAllApplicants
            .addCase(getAllApplicants.pending, state => {
                return { ...state, allApplicants: { ...state.allApplicants, status: 'loading' } };
            })
            .addCase(getAllApplicants.fulfilled, (state, action) => {
                return { ...state, allApplicants: { ...state.allApplicants, status: 'success', data: action.payload } };
            })
            .addCase(getAllApplicants.rejected, (state, action) => {
                return { ...state, allApplicants: { ...state.allApplicants, status: 'failed', error: (action.payload as any).error } };
            })
            // getAllAgents
            .addCase(getAllAgents.pending, state => {
                return { ...state, allAgents: { ...state.allAgents, status: 'loading' } };
            })
            // getTopAgents
            .addCase(getTopAgents.fulfilled, (state, action) => {
                if (action.meta.arg === "month") {
                  state.monthAgents = (action.payload as any); 
                } else if (action.meta.arg === "year") {
                  state.yearAgents = (action.payload as any); 
                }
              })
            .addCase(getAllAgents.fulfilled, (state, action) => {
                return { ...state, allAgents: { ...state.allAgents, status: 'success', data: action.payload } };
            })
            .addCase(getAllAgents.rejected, (state, action) => {
                return { ...state, allAgents: { ...state.allAgents, status: 'failed', error: (action.payload as any).error } };
            })
            // getAllPropositionsStatuses
            .addCase(getAllPropositionsStatuses.pending, state => {
                return { ...state, allPropositionsStatuses: { ...state.allPropositionsStatuses, status: 'loading' } };
            })
            .addCase(getAllPropositionsStatuses.fulfilled, (state, action) => {
                return { ...state, allPropositionsStatuses: { ...state.allPropositionsStatuses, status: 'success', data: action.payload } };
            })
            .addCase(getAllPropositionsStatuses.rejected, (state, action) => {
                return { ...state, allPropositionsStatuses: { ...state.allPropositionsStatuses, status: 'failed', error: (action.payload as any).error } };
            })
        }
    }
);

export default filterSlice.reducer;