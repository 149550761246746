import axios from 'axios';
import { UsernameToken } from 'wsse';
import dayjs from 'dayjs';

// Axios instance with default configuration
const axiosInstance = axios.create({
  baseURL: 'https://your-oro-commerce-api.com', // Replace with your API base URL
  withCredentials: true, // Enable credentials for all requests
});

// Function to generate WSSE headers
function generateHeaders(config: any = {}) {
  const username = 'intergestion-admin'; 
  const password = '8a941466ea73942cbbfac5c9634118c18c182d07'; 

  // Generate a new WSSE token for each request
  const token = new UsernameToken({
    username: username,
    password: password,
    created: dayjs().format(), 
  });

  const wsseHeader = token.toString({ nonceBase64: true });

  config.headers = {
    ...config.headers,
    Authorization: 'WSSE profile="UsernameToken"',
    'X-WSSE': wsseHeader,
  };

  return config;
}

const axiosService = {
  get: function (url: string, config?: any) {
    return axiosInstance.get(url, generateHeaders(config));
  },
  post: function (url: string, data: any, config?: any) {
    return axiosInstance.post(url, data, generateHeaders(config));
  },
  put: function (url: string, data: any, config?: any) {
    return axiosInstance.put(url, data, generateHeaders(config));
  },
  delete: function (url: string, config?: any) {
    return axiosInstance.delete(url, generateHeaders(config));
  },
  patch: function (url: string, data: any, config?: any) {
    return axiosInstance.patch(url, data, generateHeaders(config));
  },
};

export default axiosService;